import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import type { AppRouter } from '@/server/trpcAppRouter.js';
import { Switch as HUISwitch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import type { TRPCClientErrorLike } from '@trpc/client';

import { ErrorAlert, ErrorAlertItem } from './Alerts.js';
import { Button } from './Buttons.js';

export const Select = (
  props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
) => {
  const { className, ...inputProps } = props;
  return (
    <select
      className={clsx(
        'block rounded-md border-0 py-1.5 text-gray-900 ring-gray-300 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6 cursor-pointer',
        {
          'cursor-pointer': !props.disabled,
          'cursor-not-allowed opacity-50': props.disabled,
        },
        className,
      )}
      {...inputProps}
    >
      {props.children}
    </select>
  );
};

export const Input = (
  props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    variant?: 'default' | 'material';
  },
) => {
  const { className, variant, ...inputProps } = props;
  return (
    <input
      className={clsx(
        'block w-full py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6',
        {
          ['rounded-md shadow-sm border-0 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400']:
            !variant || variant === 'default',
          ['border-0 border-b border-b-gray-300 focus:border-b-2 focus:ring-0 focus:border-b-gray-300']:
            variant === 'material',
        },
        className,
      )}
      {...inputProps}
    />
  );
};

export const Label = (
  props: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>,
) => {
  const { children, className, ...labelProps } = props;
  return (
    <label
      className={clsx(
        'block text-sm font-medium leading-6 text-gray-900 dark:text-white',
        className,
      )}
      {...labelProps}
    >
      {children}
    </label>
  );
};

export const FileInput = (
  props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
) => {
  const { className, defaultValue, ...inputProps } = props;
  const ref = useRef<HTMLInputElement>(null);

  return (
    <>
      <div className="grid w-full max-w-sm items-center gap-1.5">
        <div className="flex justify-between items-center">
          <input className="hidden" type="file" ref={ref} {...inputProps} />
          <Button
            variant="outline"
            onClick={() => {
              ref.current?.click();
            }}
          >
            Choose File
          </Button>
          {defaultValue ? (
            <a
              className="flex-1 text-sm text-ellipsis pl-2 pr-2"
              href={defaultValue.toString()}
              id="file-name"
            >
              {defaultValue.toString()?.split('/').pop()?.split('_').slice(1)}
            </a>
          ) : (
            <div className="flex-1 text-sm text-ellipsis pl-2 pr-2">No file chosen</div>
          )}
          {defaultValue ? (
            <Button variant="no-border" size={1}>
              <XMarkIcon width={24} />
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};

// {error?.data?.zodError?.fieldErrors ? (
//   <TRPCFormError
//     errors={Object.entries(error?.data?.zodError?.fieldErrors)?.map(
//       ([field, error], index) => ({
//         key: `${field}-${index}`,
//         message: error!.join(', '),
//       }),
//     )}
//   />
// ) : null}
// {error && !error?.data?.zodError ? (
//   <ErrorAlert
//     errors={[
//       {
//         key: error.data?.code ?? `error-1-${error.data?.code}`,
//         message: error.data?.code!,
//       },
//     ]}
//   />
// ) : null}

const pluralizedErrorTitle = (count: number) =>
  count === 1
    ? `There was an error with your submission`
    : `There were ${count} errors with your submission`;

export function TRPCFormError(props: { errors: TRPCClientErrorLike<AppRouter> }) {
  const fieldErrors = props.errors?.data?.zodError?.fieldErrors ?? null;
  const fieldErrorEntries = fieldErrors ? Object.entries(fieldErrors) : null;
  if (fieldErrorEntries && fieldErrorEntries.length) {
    return (
      <ErrorAlert title={pluralizedErrorTitle(fieldErrorEntries.length)}>
        {fieldErrorEntries.map(([field, errorMessage], index) => (
          <ErrorAlertItem key={`${index}-${field}`}>{errorMessage}</ErrorAlertItem>
        ))}
      </ErrorAlert>
    );
  }

  const formErrors = props.errors?.data?.zodError?.formErrors ?? null;
  if (formErrors && formErrors.length) {
    return (
      <ErrorAlert title={pluralizedErrorTitle(formErrors.length)}>
        {formErrors.map((message) => (
          <ErrorAlertItem key={message}>{message}</ErrorAlertItem>
        ))}
      </ErrorAlert>
    );
  }

  // TODO: Clean up how this error message will get formatted
  return <ErrorAlert title={props.errors.message} />;
}

export default function Switch(props: {
  className?: string;
  value: boolean;
  onLabel: string;
  offLabel: string;
  onChange: (enabled: boolean) => void;
}) {
  const [enabled, setEnabled] = useState(props.value);

  useEffect(() => {
    setEnabled(props.value);
  }, [props.value]);

  return (
    <div className={clsx('flex flex-row', props.className)}>
      <Label className="mr-3">{props.offLabel}</Label>
      <HUISwitch
        checked={enabled}
        onChange={(enabled) => {
          props.onChange(enabled);
          setEnabled(enabled);
        }}
        className={clsx(
          enabled ? 'bg-brand-medium-pink' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
        )}
      >
        <span
          aria-hidden="true"
          className={clsx(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </HUISwitch>
      <Label className="ml-3">{props.onLabel}</Label>
    </div>
  );
}
