import Image from 'next/image';
import Link from 'next/link';

import { MainWithBackground } from '@/components/Main.js';
import { Heading } from '@/components/Text.js';

import { TemplateDocumentHead } from './TemplateDocumentHead.js';

export default function TemplateAuth(props: { title: string; children: React.ReactNode }) {
  return (
    <MainWithBackground>
      <TemplateDocumentHead title={props.title} />
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <Link href="/">
            <Image
              className="mx-auto h-10 w-auto"
              src="/assets/logos/dark.svg"
              alt="3DPage.io"
              width={512}
              height={88.2}
              priority
            />
          </Link>
          <Heading tagName="h1" size={3} className="mt-10">
            {props.title}
          </Heading>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">{props.children}</div>
      </div>
    </MainWithBackground>
  );
}
