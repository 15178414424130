import clsx from 'clsx';

import { Spinner } from './Spinner.js';

interface AdditionButtonProps {
  full?: boolean;
  size?: 1 | 2 | 3 | 4 | 5;
  variant?: 'standard' | 'outline' | 'no-border';
  isLoading?: boolean;
}

export const Button = (
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
    AdditionButtonProps,
) => {
  const { children, className, full, size, variant, isLoading, ...buttonProps } = props;
  return (
    <button
      className={clsx(
        'flex justify-center rounded-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 relative',
        {
          'w-full': full,
          'shadow-sm bg-brand-dark-pink hover:bg-brand-medium-pink text-white':
            !variant || variant === 'standard',
          'text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 bg-transparent':
            variant === 'outline',
          'dark:hover:bg-light-transparent-20 hover:bg-dark-transparent-20':
            variant === 'outline' && !props.disabled,
          'text-gray-900 dark:text-white': variant === 'no-border',
          'px-2 py-1 text-xs': size === 1,
          'px-2 py-1 text-sm': size === 2,
          'px-2.5 py-1.5 text-sm': size === 3,
          'px-3 py-2 text-sm': !size || size === 4,
          'px-3.5 py-2.5 text-sm': size === 5,
          'opacity-50 cursor-not-allowed': props.disabled,
        },
        className,
      )}
      {...buttonProps}
    >
      {children}
      {isLoading ? (
        <div className="absolute bottom-0 top-0 left-0 right-0 flex items-center justify-center rounded-md bg-light-transparent-50">
          <Spinner />
        </div>
      ) : null}
    </button>
  );
};

export const ImportantLink = (
  props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
) => {
  const { children, className, ...anchorProps } = props;
  return (
    <a
      className={clsx(
        'font-semibold leading-6 text-brand-dark-pink hover:text-brand-medium-pink',
        className,
      )}
      {...anchorProps}
    >
      {children}
    </a>
  );
};
