import { useRouter } from 'next/router';

import { Button, ImportantLink } from '@/components/Buttons.js';
import { Input, Label, TRPCFormError } from '@/components/Forms.js';
import TemplateAuth from '@/components/TemplateAuth.js';
import { trpc } from '@/utils/trpcClient.js';

export default function Login() {
  const router = useRouter();

  const { mutate, error } = trpc.user.login.useMutation({
    onSuccess() {
      router.push('/home').then(() => {
        window.location.reload();
      });
    },
    trpc: {
      context: {
        forceHTTP: true,
      },
    },
  });
  return (
    <TemplateAuth title="Sign in to your account">
      <form
        className="space-y-6"
        action="#"
        method="POST"
        onSubmit={(e) => {
          e.preventDefault();
          mutate({
            email: e.currentTarget.querySelector<HTMLInputElement>('#email')?.value ?? '',
            password: e.currentTarget.querySelector<HTMLInputElement>('#password')?.value ?? '',
          });
        }}
      >
        {error ? <TRPCFormError errors={error} /> : null}
        <div>
          <Label htmlFor="email">Email address</Label>
          <div className="mt-2">
            <Input id="email" name="email" type="email" autoComplete="email" required />
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between">
            <Label htmlFor="password">Password</Label>
            <div className="text-sm">
              <ImportantLink href="/forgot-password">Forgot password?</ImportantLink>
            </div>
          </div>
          <div className="mt-2">
            <Input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
            />
          </div>
        </div>

        <div>
          <Button full type="submit">
            Sign in
          </Button>
        </div>
      </form>

      <p className="mt-10 text-center text-sm text-gray-500">
        Not a member? <ImportantLink href="/signup">Join now!</ImportantLink>
      </p>
    </TemplateAuth>
  );
}
